/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 75번째 로그에서는 당근마켓 개발자 에릭을 모시고 미국 워싱턴주 킹카운티, 공적 마스크 재고 현황, Go 입문  등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bdes.nile.or.kr:444/nile/base/bdesMain.do"
  }, "독학학위제 - 국가평생교육진흥원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ustraveldocs.com/kr_kr/kr-niv-typework.asp"
  }, "미국 비자 신청 | 취업 비자 - 대한민국 (한국어)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23579146"
  }, "“미국투자이민 기본에 충실해야”…투자이민 업체 선정이 관건 - 중앙일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%ED%82%B9_%EA%B5%B0_%27%EC%9B%8C%EC%8B%B1%ED%84%B4%EC%A3%BC%28"
  }, "킹 군 (워싱턴주) - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EA%B5%B0_%28%EB%AF%B8%EA%B5%AD%29"
  }, "군 (미국) - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.microsoft.com/2020/03/04/kurt-delbenes-march-4-guidance-to-king-county-employees/"
  }, "Kurt DelBene’s March 4 guidance to King County employees - Stories")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.donga.com/news/article/all/20200228/99921958/1"
  }, "코로나 검사비용…한국 16만원 vs 미국 400만원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.einfomax.co.kr/news/articleView.html?idxno=4075726"
  }, "구글, 유럽·중동·아프리카 지역 전직원에 재택 근무 권고 - 연합인포맥스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/world/view/2020/02/210744/"
  }, "코로나 공포에 휩싸인 日…가짜뉴스에 놀라 화장지 사재기 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gisanddata.maps.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6"
  }, "Coronavirus COVID-19 (2019-nCoV)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.etoday.co.kr/news/view/1866417"
  }, "“서버D램 가격 상승, 반도체 코로나19 타격 상쇄”-KB증권 - 이투데이")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://intl.cloud.tencent.com/"
  }, "Tencent Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23726298"
  }, "서울 최대 집단감염 터졌다…구로 콜센터 직원·가족 최소 32명 - 중앙일보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bizn.donga.com/3/all/20200318/100212319/1"
  }, "“집은 답답해”…재택근무 직장인·개학연기된 대학생 동네카페로 몰렸다 : 비즈N")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/politics/article/2020022401967"
  }, "대한상의 출퇴근 시차제·재택근무 권고…코로나19 확산 방지 차원 | 한경닷컴")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=31wbU4QavfM"
  }, "영상 - BBC 방송사고의 순간 / YTN (Yes! Top News) - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sounddevices.com/product/mixpre-3-ii/"
  }, "MixPre-3 II - Sound Devices")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.presonus.com/products/studiolive-ar-hybrid-mixers"
  }, "StudioLive AR Hybrid Mixers | Products | PreSonus")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.data.go.kr/dataset/15043025/openapi.do"
  }, "공공데이터포털 - 건강보험심사평가원_공적 마스크 판매 정보")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://golang.org/"
  }, "The Go Programming Language")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gin-gonic/gin"
  }, "gin-gonic/gin: Gin is a HTTP web framework written in Go (Golang). It features a Martini-like API with much better performance -- up to 40 times faster. If you need smashing performance, get yourself some Gin.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.traefik.io/"
  }, "Traefik")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mingrammer.com/gobyexample/"
  }, "Go by Example")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hanbit.co.kr/store/books/look.php?p_code=B1938330682"
  }, "Head First Go")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mingrammer.com/"
  }, "mingrammer's note")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/uber-go/fx"
  }, "uber-go/fx: A dependency injection based application framework for Go.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/99designs/aws-vault"
  }, "99designs/aws-vault: A vault for securely storing and accessing AWS credentials in development environments")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
